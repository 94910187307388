/* app  header */

.app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 60px;
    background-color: #fff;
    border-bottom: 0.2px solid #EAECF0;
    position: sticky;
    top: 0px;
    z-index: 999;
}

.config-header {
    display: flex;
    justify-content: space-around;
    padding: 15px;
    border-bottom: 0.2px solid #EAECF0;
}

.config-toggle{
    position: absolute;
    right: 0px;
}

.app-header .app-logo .logo {
    height: 55px;
    object-fit: cover;
}


.app-header .app-links a,
.config-header .config-links a {
    color: #344054e0;
    font-family: 'Inter';
    font-size: 15px;
    line-height: 24px;
    padding: 10px;
    font-weight: 600;
    white-space: nowrap;
    text-decoration: none;
}

.app-header .app-links .active,
.config-header .config-links .active {
    background-color: #F9FAFB;
    color: #101828 !important;
    font-family: 'Inter';
    font-size: 15px;
    line-height: 24px;
    font-weight: 600;
    border-radius: 6px;
    transition-delay: 80ms;
    white-space: nowrap;
}


.app-header .profile-settings{
    display: flex;
    align-items: center;
    gap: 10px;
}

.app-header .profile-settings p{
    font-size: 12px;
    margin-bottom: 0px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.app-header .profile-settings .header-icons{
   cursor: pointer;
}

/*  app Header Start */

.mobile-view-only h1 {
    font-size: 12px;
    font-size: 13.5px;
    color: #5C7181;
    font-family: 'DM Sans' !important;
    font-style: normal;
    font-weight: 600;
    line-height: 0px;
    letter-spacing: 0.01em;
    margin-top: 8px;
    margin-left: 10px;
    cursor: pointer;
}

.dra ul li {
    font-family: 'DM Sans' !important;
    font-style: normal;
    font-weight: 600;
}

.avatar-header {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.logout {
    width: 20px;
    margin-right: 5px;
    margin-top: 2px;
    cursor: pointer;
}

.app-auth {
    font-size: 13.5px;
    color: #5C7181;
    font-family: 'DM Sans' !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.01em;
}

.app-auth span {
    cursor: pointer;
}

.warning-pop-up .ant-modal-header,
.warning-pop-up .ant-modal-footer {
    display: none !important;
}

/* .warning-pop-up .ant-modal-body {
    height: 62vh;
} */

.warning-pop-up .pop-table {
    height: 60vh;
}

.active h1 {
    color: red;
    text-align: center;
}

.alert h1 {
    text-align: center;
}

.pop-table {
    margin: 0px !important;
}
.profile_img{
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50px;
}

.profile_img_sub{
    width: 33px;
    height: 33px;
    object-fit: cover;
    border-radius: 50px;
    margin-right: 8px;
}
@media only screen and (max-width: 600px) {
    .app-header .app-links a {
        display: none;
    }

    .app-header {
        position: relative !important;
        padding: 20px 10px;
    }
}

@media only screen and (min-width: 600px) {
    .app-header .mobile-view-only {
        display: none;
    }
}

@media (min-width:801px) {

    /* tablet, landscape iPad, lo-res laptops ands desktops */
    .app-header .mobile-view-only {
        display: none;
    }
}

@media (min-width:1025px) {

    /* big landscape tablets, laptops, and desktops */
    .app-header .mobile-view-only {
        display: none;
    }
}

@media (min-width:1281px) {

    /* hi-res laptops and desktops */
    .app-header .mobile-view-only {
        display: none;
    }
}

/* app  header */
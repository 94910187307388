* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

img {
  vertical-align: middle;
  border-style: none;
}

.new-hr {
  background-color: #EAECF0 !important;
  height: 1px;
}

.new-form-hr {
  background-color: #EAECF0 !important;
  height: 0.2px;
  margin: 25px 0px;
}


body {
  font-family: "Inter" !important;
}


.text-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
}

.label-container {
  margin-bottom: 4px;
  font-size: 12px;
}

form {
  position: relative;
}

.inventory-main {
  margin-top: 20px;
}

.reports-main {
  margin: 0px 60px;
  margin-bottom: 30px;
}

/* scrollbar start*/

::-webkit-scrollbar {
  width: 0px;
  display: none;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(166, 166, 166);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2C2F73;
  border-radius: 10px;
}

/* scrollbar End*/

/* Input field Start */

/* .css-1n8sjgh-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Inter" !important;
  font-size: 13px;
  height: 38px;
  border: none;
  border-radius: 6px;
  font-weight: 600;
} */


.form-label {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin-bottom: 6px;
  color: #344054;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.date-picker-filter-label {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #344054;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.create-form h3 {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin-bottom: 6px;
  color: #344054;
}

.category-filter {
  padding: 0px 0px;
  min-height: 150px;
  max-height: 190px;
  overflow: scroll;
  border-radius: 4px;
}

.category-filter ul {
  list-style: none;
}

.category-filter ul li {
  margin: 3px 0px;
  padding: 4px 5px;
  cursor: pointer;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
}

.asset-filter h3 {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  margin-bottom: -8px;
  color: black;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.category-filter ul li:hover {
  background-color: #F5F5F5
}

.category-filter .active {
  background-color: rgba(25, 118, 210, 0.08);
}


.create-form .form-buttons {
  display: flex;
  justify-content: center;
  align-items: end;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 10px;
}

.create-form .form-buttons .css-i4bv87-MuiSvgIcon-root {
  margin-right: 6px;
  color: #31A566;
}



.css-1n8sjgh-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border: none;
  box-shadow: #d32f2fa8 0px 1px 1px, #d32f2fa8 0px 0px 1px 1px;
}

.css-1mts2al-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border: none;
  box-shadow: #d32f2fa8 0px 1px 1px, #d32f2fa8 0px 0px 1px 1px;
}

/* .css-1n8sjgh-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.css-1n8sjgh-MuiInputBase-root-MuiOutlinedInput-root:focus-within .MuiOutlinedInput-notchedOutline{
  border: none;
  box-shadow: rgba(0, 0, 0, 0.23)0px 1px 3px 0px, rgba(0, 0, 0, 0.23) 0px 0px 0px 1px;
} */

.form-err-msg {
  position: relative;
}

.input-error {
  color: red;
  font-family: "Inter" !important;
  font-size: 13px;
  margin-top: 45px;
}


.select-error {
  color: red;
  font-family: "Inter" !important;
  font-size: 13px;
  position: absolute;
  left: 0;
  margin-top: 70px;
}

.error-message {
  color: red;
  font-family: "Inter" !important;
  font-size: 13px;
  padding-top: 5px;
}

.custom-date-picker {
  box-sizing: border-box !important;
  font-family: "Inter" !important;
  font-size: 15px !important;
  line-height: 24px;
  height: 44px !important;
  font-weight: 500 !important;
  color: #101828;
}


.css-i4bv87-MuiSvgIcon-root {
  width: 18px;
}

/* InputField End */

/* select start */


.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  display: flex !important;
  align-items: center !important;
}

/* select send */


/* muibtns start */

.css-cstir9-MuiButton-startIcon {
  margin-right: 2px;
}

.css-i4bv87-MuiSvgIcon-root {
  width: 22px;
  height: 22px;
}


/* mui butns end */


/* inner-header start */

.config-create-main {
  margin-top: 20px;
  margin: 0px 60px;
}


.config-create-main .createEdit-header {
  padding: 15px 0px;
  margin: 0px;
}

.inner-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  margin: 0px 60px;
  border-bottom: 0.2px solid #EAECF0;
}

.inner-header .title h1 {
  font-family: "Inter";
  font-size: calc(23px);
  font-weight: 700;
  color: #101828;
}

.inner-header .title img {
  width: 45px !important;
  height: 45px !important;
  margin-top: -3px;
}

.inner-header .title p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 5px;
  color: #475467;
}

.inner-header .title .nav-back {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.inner-header .title .nav-back span {
  font-family: "Inter";
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #31A566;
}

.inner-header .config-create-buttons {
  display: flex;
  white-space: nowrap;
  align-items: center;
  gap: 20px;
}

.inner-header .title .nav-back .css-i4bv87-MuiSvgIcon-root {
  width: 25px;
  height: 25px;
  color: #31A566;
}


.dashboard-inner-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  margin-bottom: 10px;
}

.dashboard-inner-header .title h1 {
  font-family: "Inter";
  font-size: calc(32px);
  font-weight: 700;
  color: #101828;
}

.dashboard-inner-header .title img {
  width: 45px !important;
  height: 45px !important;
  margin-top: -3px;
}

.dashboard-inner-header .title p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: 0px;
  color: #475467;
}

.dashboard-inner-header .title .nav-back {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.dashboard-inner-header .title .nav-back span {
  font-family: "Inter";
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #31A566;
}

.dashboard-inner-header .title .nav-back .css-i4bv87-MuiSvgIcon-root {
  width: 25px;
  height: 25px;
  color: #31A566;
}




/* inner-header end */


/* filter start*/

.config-filter .config-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 60px 5px 60px;
}

.config-filter .config-title h3 {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
}

.dashboard-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.dashboard-pagination {
  margin-top: 12px;
}

.config-filter-fields {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard-filter .date-filters ul {
  list-style: none;
  display: flex;
}

.dashboard-filter .date-filters ul li {
  padding: 10px 20px;
  border-left: 1px solid #b8bcc2;
  border-top: 1px solid #b8bcc2;
  border-bottom: 1px solid #b8bcc2;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  white-space: nowrap;
}

.dashboard-filter .date-filters ul li:hover {
  background-color: #F9FAFB;
}

.dashboard-filter .date-filters ul li:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.dashboard-filter .date-filters ul li:last-child {
  border-right: 1px solid #b8bcc2;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.dashboard-status-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 20px;
  gap: 20px;
  width: 100%;
  border-radius: 10px;
  white-space: nowrap;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
}

.dashboard-status-card h3 {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #101828;
}

.dashboard-status-card h2 {
  font-family: "Inter";
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  text-align: left;
  color: #101828;
}

.dashboard-status-card .graph-details {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
}

.dashboard-status-card .last-mth {
  color: #475467;
}

.dashboard-status-card span {
  margin-top: 5px;
  font-family: "Inter";
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #027A48;
}

.dashboard-status-card .graph-details .graph-persentage-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dashboard-status-card .area-chart {
  display: flex;
  align-items: end;
  margin-left: 20px;
  width: 50%;
  /* position: absolute; */
  right: 20px;
  bottom: 40px;
  justify-content: flex-end;
}

.short-icon {
  width: 16px;
  height: 16px;
  margin-left: 0px;
  cursor: pointer;
  color: #475467;
}

/* filter end*/

/* pagination start */

.common-pagination {
  margin: 0px 55px;
  margin-top: 12px;
}


.common-pagination .css-fg3m5r-MuiButtonBase-root-MuiButton-root {
  padding: 0px !important;
}

.css-1a41st4-MuiStack-root {
  display: flex;
  justify-content: space-between;
}

.css-1ga1fta-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 600;
  line-height: 20px;
  color: #1D2939;
}

.css-1ga1fta-MuiButtonBase-root-MuiPaginationItem-root {
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 600;
  line-height: 20px;
  color: #1D2939;
}

/* pagination end */

/* thales table start */

.common-table {
  max-width: 100%;
  height: calc(100vh - 313px);
  overflow-x: auto;
  margin: 0px 60px;
}

.inventory-table {
  max-width: 100%;
  height: calc(100vh - 277px);
  overflow-x: auto;
  margin: 0px 60px;
}

.scanned-table {
  max-width: 100%;
  height: calc(100vh - 312px);
  overflow-x: auto;
  margin: 0px 60px;
}

.common-table .expiry-date-error td .table-inactive-btn {
  background-color: white;
  color: black;
  height: 29px;
  padding: 7px;
}

.common-table table {
  width: 100%;
  font-family: 'Inter';
  border-collapse: collapse;
}

.common-table table thead {
  background-color: #f4f5f8;
  border-bottom: 1px solid #EAECF0;
  position: sticky;
  top: 0;
  z-index: 99;
}

.common-table .table-cursor {
  cursor: pointer;
}

.dif-title {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
}

.dif-title-total {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;

}

.common-table table tr {
  white-space: nowrap;
}

.common-table table tbody tr .common-table-Avatar {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Inter";
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  color: #344054;
}

.common-table table tbody tr .common-table-name {
  font-family: "Inter";
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  color: #344054;
}

.common-table table tbody tr .product_link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.common-table table tbody tr .common-table-floor-name {
  background-color: #F2F4F7;
  border: none;
  padding: 3px 10px;
  border-radius: 30px;
  color: #344054;
  font-family: "Inter";
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
}

.common-table table tbody tr .multi-color {
  display: inline-block;
  vertical-align: middle;
  align-items: center;
  gap: 5px;
  padding: 3px 8px;
  width: auto;
  border-radius: 30px;
  font-family: "Inter";
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
}

.common-table table tbody tr .dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 1px;
}

.common-table table thead tr th {
  padding: 15px 10px 15px 0px;
  color: #475467;
  text-align: left;
  padding-right: 20px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.common-table table thead tr th:first-child:before {
  width: 0px;
  height: 0em;
}

.common-table table thead tr th .anticon[tabindex] {
  cursor: pointer;
  padding-left: 10px;
  color: #2C2F73;
}

.common-table table thead tr {
  padding-bottom: 10px;
  text-align: left;
  white-space: nowrap;
}

.common-table table tbody tr td {
  padding: 10px 0px;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  padding-right: 20px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #475467;
  width: 300px;
}

.common-table table tbody tr {
  border-bottom: 1px #EAECF0 solid;
}

.bulk-upload-table {
  height: calc(100vh - 320px);
}

.bulk-upload-table table tbody tr {
  border-bottom: none;
}

.bulk-upload-table table tbody tr {
  display: table-row;
}

.bulk-upload-table table tbody tr td {
  padding: 4px 4px;
  min-width: 200px;
}

.common-table table tbody .clear-border {
  border-bottom: none !important;
}


.common-table .action-btns {
  display: flex;
  align-items: center;
  gap: 20px;
}

.common-table .table-edit {
  cursor: pointer;
  width: 18px;
  margin-right: 10px;
}

.common-table .table-view {
  cursor: pointer;
  width: 18px;
  margin-left: 15px;
}


.common-table .header-pointer {
  cursor: pointer;
}

.common-table .no-data {
  height: calc(100vh - 373px);
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding-top: 70px;
}

.common-table .no-data img {
  width: 60px;
}

.common-table .action-btns span {
  color: #2C2F73;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
}

/* thales table end */


/* modal style start */

.add-user {
  background-color: red;
}


.model--header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  padding-top: 20px;
  position: sticky;
  top: 0px;
  z-index: 99;
  background-color: white;
}

.model--header .modal-title h1 {
  font-family: "Inter";
  font-size: calc(20px);
  font-weight: 600;
  color: #101828;
}

.model--header .modal-title img {
  width: 45px !important;
  height: 45px !important;
  margin-top: -3px;
}

.model--header .css-i4bv87-MuiSvgIcon-root {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 30px;
  right: 0px;
  cursor: pointer;
  color: #667085;
}

.model--header a {
  margin-right: 60px;
  font-size: 14px;
  text-decoration: underline;
}

.modal-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.modal-btns .modal-btns-center {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 40%;
}

.model--header .modal-title p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 0px;
  color: #475467;
}


/* modal style end */


/* dropzon-upload start */

.dropzone {
  border: 1.5px solid #EAECF0;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  transition: background-color 0.3s ease;
  cursor: pointer;
  gap: 8px;
  margin-top: 10px;
}

.dropzone img {
  width: 50px;
  height: 50px;
}

.dropzone p {
  text-align: center;
}

.dropzone p {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #475467;
  line-height: 25px;
}

.dropzone p span {
  font-family: "Inter";
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #31A566;
}

.file-container {
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  border: 1px solid #39BA74;
  margin-top: 10px;
  border-radius: 10px;
  gap: 10px;
}

.file-container .file-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.file-container .file-details .file-doc-type {
  display: flex;
  align-items: center;
  gap: 10px;
}

.file-container .file-details .file-doc-type img {
  margin-top: -5px;
}


.file-container .file-details .file-name {
  display: block;
}

.file-container .file-details .css-i4bv87-MuiSvgIcon-root {
  width: 20px;
  height: 20px;
  color: #39BA74;
}

.file-container .file-details .file-name h3 {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #344054;
}

.file-container .file-details .file-name p {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #475467;
}

.file-container .progress-container {
  position: relative;
  margin-left: 40px;
}

.file-container .progress-container .css-eglki6-MuiLinearProgress-root {
  width: 85%;
  height: 5px;
  border-radius: 30px;
}

.file-container .progress-container p {
  position: absolute !important;
  right: 0px;
  bottom: -6px;
  color: #344054 !important;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.css-5xe99f-MuiLinearProgress-bar1 {
  background-color: #39BA74;
}

/* dropzon-upload end */


/* confirm Dialog start */

.confirm-dialog {
  position: relative;
  min-height: 185px;
}

.confirm-dialog-body {
  position: absolute;
  width: 100%;
}

.confirm-dialog-body .confirm-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 23px;
}

.confirm-dialog-body .confirm-header img {
  width: 60px;
}

.confirm-dialog-body .confirm-header p {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

.confirm-dialog-body .confirm-btns {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}


/* confirm dialog end */


/* profile page start*/

.media-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
}

.media-object .edit-icon {
  position: absolute;
  bottom: 10px;
  margin-left: 100px;
}

.media-object span {
  width: 60px;
  height: 60px;
  padding: 10px;
  border-radius: 50%;
  background-color: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  cursor: pointer;
}

.img-object {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
}

/* profile page end */


.role-form .switch-container .switch-span {
  font-family: "Inter" !important;
  font-size: 15px;
  font-weight: 700;
  color: #09101D;
  margin-top: 20px;
  margin-bottom: 20px;
}


.role-form .switch-container tr td {
  padding: 16px 16px 16px 0px;
  border-bottom: 1px solid #d9d9d9;
  font-family: "Inter" !important;
  font-size: 14px;
  font-weight: 600;
  color: #09101D;
  margin-left: 10px;
}


.role-form .css-1ovu9j1-MuiTypography-root {
  color: #09101d;
  font-family: "Inter" !important;
  font-size: 13px;
  font-weight: 600;
}

/* product_collapse */
.product_collapse {}

.product_collapse .head_collapse {}

.product_collapse .head_collapse .header_collapse {
  background-color: #f7f7f7;
  min-height: 20px;
  padding: 0px 10px;

}

.css-eqpfi5-MuiAccordionSummary-content {
  margin: 8px 0px !important;
  font-weight: 600;
  color: #2d2b2b;
}

.collapse_search {
  padding: 15px 0px 5px 0px !important;
}

.collapse_Table {
  margin: 0px 0px 0px 0px !important;
}

.inventory_Details {
  margin: 20px 60px;
}

.inventory_Details .circle_blue {
  width: 10px;
  height: 10px;
  background: rgb(75 145 238);
  border-radius: 23px;
}

.inventory_Details .circle_pink {
  width: 10px;
  height: 10px;
  background: rgb(238 75 230);
  border-radius: 23px;
}

.inventory_Details .circle_green {
  width: 10px;
  height: 10px;
  background: rgb(62 201 42);
  border-radius: 23px;
}

.inventory_remark h6 {
  font-size: 12px;
  color: #827676;
  font-weight: 500;
  padding-bottom: 3px;
}

.inventory_Details_container h5 {
  color: #827676;
}

.csv_name {
  background-color: #eeeeee;
  padding: 4px 8px;
  border-radius: 8px;
}

.csv_name p {
  font-size: 11px;
  font-weight: 600;
}

.Scan_download :hover {
  color: blue;
}

/* end product_collapse */

/* report_table */
.scrollable-table {
  display: flex;
}

.scroll-table {
  overflow-x: scroll !important;
}

.report_table {
  overflow-x: visible;
  margin: 0px !important;
  /* padding: 20px 0px 0 0; */
}

.non-scroll-table {
  overflow-x: visible !important;
}

.config-filter-fields .MuiInputBase-input {
  padding: 8.5px 14px;
}

.history-table table {
  width: 100%;
  font-family: 'Inter';
  border-collapse: collapse;
}

.history-table table thead {
  background-color: #f4f5f8;
  border-bottom: 1px solid #EAECF0;
  /* position: sticky; */
  top: 0;
  z-index: 99;
  height: 59px;
}

.history-table table thead tr {
  padding-bottom: 10px;
  text-align: left;
  white-space: nowrap;
}

.history-table table thead tr th {
  padding: 15px 10px 15px 10px;
  color: #475467;
  /* text-align: center; */
  /* padding-right: 0px; */
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.history-table table tbody tr {
  border-bottom: 1px #EAECF0 solid;
}

.history-table table tbody tr td {
  padding: 10px 10px;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  /* padding-right: 0px; */
  /* text-align: center; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #475467;
  width: 300px;
  height: 70px;
}

.shift-out-cell {
  background-color: #f4f8ff;
  cursor: pointer;
}

.scroll-table::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
  display: block !important;
}

.scroll-table table tbody tr td {
  padding: 5px !important;
}

/* end report_table */

/* tooltip */
/* .MuiTooltip-popper {
  width: 292px;
} */
.auth_count h5 {
  text-wrap: nowrap;
  width: 58px;
}

.auth_not_done {
  padding: 5px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
}

.auth_not_done h3 {
  font-size: 20px;
}

.auth_done {
  background: #cbe0cb;
}

.not-in-range {
  background-color: #4d8bca;
}

.count-failed {
  background-color: #bea938;
}

.count-missed {
  background-color: #e27373;
}

.auth_available {
  margin-top: 6px;
  border: 1px solid #cbe0cb;
  padding: 2px 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loggedout {
  margin-top: 6px;
  border: 1px solid #b9cde1;
  padding: 2px 5px;
  border-radius: 10px;
  color: #599adc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.missed {
  margin-top: 6px;
  border: 1px solid #e27373;
  padding: 2px 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auth_away {
  margin-top: 6px;
  border: 1px solid #c8b995;
  padding: 2px 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*End tooltip */
.change_password {
  cursor: pointer;
  color: blue;
}

.holiday-late-entry-cell {
  background-color: rgb(231, 231, 88) !important;
  color: red !important;
}

.holiday-cell {
  background-color: rgb(231, 231, 88) !important;
  color: black !important;
}

.common-table table thead tr th:first-child {
  padding: 15px 0px 15px 15px;
}

.common-table table tbody tr td:first-child {
  padding-right: 0;
  padding-left: 15px;

}

.first_col {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
}
.png_col{
  display: flex;
  align-content: center;
  justify-content: center;
}
.auth_available .status h3{
  color:green
}
@media (max-width: 375px) {
  .confirm-dialog {
    position: relative;
    min-height: 150px;
  }

  .confirm-dialog-body .confirm-header {
    gap: 15px;
  }

  .confirm-dialog-body .confirm-btns {
    margin-top: 20px;
  }

  .confirm-dialog-body .confirm-header img {
    width: 50px;
  }
}